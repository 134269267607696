.admin {
  display: flex;
}

.side-bar {
  border-right: 2px solid #d4d4d4;
  /* width: 16%; */
  width: 21.5rem;
  padding: 2.8rem 3rem 0 2rem;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.side-bar h4 {
  font-weight: 500;
  font-size: 1.2rem;
}

.side-bar .logo img {
  width: 78%;
}

.side-bar ul {
  margin: 5rem 0 8rem 0;
  flex-direction: column;
  gap: 3.5rem;
}

.side-bar li {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  padding-left: 1rem;
}

.side-bar button {
  padding: 0;
  border: none;
  background: none;
}

.side-bar button a {
  display: flex;
  align-items: center;
}

.side-bar button span {
  margin-left: 1rem;
  color: #c30532;
  font-weight: 600;
  font-size: 1.2rem;
}

.side-bar li img {
  width: 13%;
}

.active {
  background-color: #c30532;
  color: #ffffff;
  padding: 1rem 0;
  border-radius: 4px;
}

.main-content {
  width: 84%;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.admin header,
.admin header,
.admin header > div:nth-of-type(2),
.admin header > div:nth-of-type(2) > div:nth-of-type(1),
.admin header > div:nth-of-type(2) > div:nth-of-type(3),
.all-members .search {
  display: flex;
  align-items: center;
}

.admin header {
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d4;
  height: 9rem;
  padding: 0 6rem;
}

.admin header .greetings img {
  width: 28%;
  border: 2px solid #bae6d4;
  border-radius: 100%;
  margin-right: 1rem;
}

.admin header > div {
  padding: 0;
}

.admin header .notification,
header .search,
.all-members .search {
  box-shadow: 2px 0px 20px 0px #00000010;
  border-radius: 8px;
}

.admin header .notification {
  padding: 0.65rem 0.7rem 0.65rem 1.1rem;
  margin: 0 2rem;
}

.admin header .notification > img {
  width: 90%;
}

.admin header input,
.chat-support input,
.all-members input {
  width: 47rem;
  border: none;
  outline: none;
  background: none;
  border-radius: 8px;
}

.all-members input {
  width: 66rem;
}

header .search {
  flex: 1;
  box-shadow: 2px 0px 20px 0px #00000010;
  padding: 0 1rem 0 2rem;
  height: 4rem;
}

.all-members .search {
  box-shadow: 2px 0px 20px 0px #00000010;
  padding: 0 1rem 0 2rem;
  height: 3.5rem;
}

.admin header .search img,
.chat-support .search img {
  width: 4%;
}

.all-members .search img {
  width: 3%;
}

table {
  padding-top: 2rem;
}

table button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
}

table button p {
  margin-left: 1rem;
  font-size: 1.2rem;
  color: #c30532;
  font-weight: 600;
  text-transform: capitalize;
}

thead {
  border-bottom: 1px solid black;
}

th {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.2rem;
}

td {
  padding: 2rem;
  vertical-align: top;
}

td.content {
  display: flex;
  gap: 1.8rem;
}

td.content h3 {
  color: #000000;
}

td.content p {
  margin-top: 0.7rem;
  font-size: 1.2rem;
}

td.content img {
  width: 100%;
}

td.content > div:nth-of-type(1) {
  width: 80%;
}
td.content.programs-content > div:nth-of-type(1) {
  width: 15%;
}

thead th:nth-of-type(2) {
  width: 40%;
}

thead th:nth-of-type(4),
thead th:nth-of-type(5) {
  text-align: left;
}

.loader,
.error {
  margin: 5rem 10rem;
  font-size: 1.8rem;
  width: 100%;
}

.admin-menu2 ul {
  padding-top: 1rem;
}

.admin-menu2 li {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  padding: 1rem 1.5rem 0.7rem 1rem;
}

.admin-menu2 li.noIcon {
  padding: 1rem 3.5rem 0.7rem 3rem;
}

.admin-menu2 h4 {
  font-weight: 600;
  font-size: 1rem;
}

.programme-form {
  padding: 2rem;
}

.programme-form input,
.programme-form textarea,
.resource-form select {
  display: block;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgb(182, 182, 182);
  outline: none;
  background-color: #f2f2f2;
  margin-top: 1rem;
}

.programme-form .dynamic-input {
  margin-top: 0;
}

.programme-form label {
  margin-top: 2rem;
}

.programme-form button {
  margin-bottom: 2rem;
  text-transform: initial;
  font-size: 1.2rem;
  color: #c30532;
  border: 1px solid rgb(182, 182, 182);
  width: 40%;
  text-align: left;
}

.programme-form input,
.programme-form textarea,
.programme-form .continue,
.resource-form select,
.programme-form .react-datepicker-wrapper {
  width: 40%;
}

.programme-form .continue,
.push-notification .continue {
  font-weight: 600;
  text-transform: none;
  font-size: 1.4rem;
  color: #e2e2e2;
  margin-left: 0;
  text-align: center;
  display: block;
}

.programme-form .calendar input {
  width: 100%;
}

.event-form.programme-form .calendar input {
  width: fit-content;
}

.input-options {
  display: flex;
  align-items: center;
}

.programme-form .delete-input {
  width: 1%;
  background: none;
  border: none;
  margin-left: -4rem;
  margin-top: 2rem;
  position: absolute;
  left: 68rem;
}

.programme-form .input-options input::placeholder {
  color: #a5a5a5;
}

.programme-form h3 {
  margin-top: 2.5rem;
}

table.fundHer td {
  border-bottom: 1px solid #ececec;
}

table.fundHer td.content > div:nth-of-type(1) {
  width: 28%;
}

table.fundHer td.content h3 {
  font-size: 1.5rem;
}

table.fundHer .feature {
  display: flex;
}

table.fundHer .feature span {
  margin-left: 1rem;
  color: #c30532;
  font-size: 1.3rem;
}

table.fundHer > thead th:nth-of-type(5) {
  text-align: center;
}

table.fundHer td.content2 > div:nth-of-type(1) {
  width: 60%;
}

.input-error {
  font-size: 1.8rem;
}

.success-img img {
  margin-left: 9rem;
}

.success-img + p {
  margin: 6rem 0 3rem 12rem;
  font-weight: 600;
}

.react-datepicker-wrapper {
  width: 37%;
}

.calendar input {
  font-size: 1.2rem;
  width: fit-content;
}

.calendar-icon {
  font-size: 1.8rem;
  position: relative;
  top: 18px;
  right: 30px;
}

.event-form input::placeholder,
.calendar-icon {
  color: #a5a5a5;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #c30532 !important;
  color: #ffffff !important;
}

.react-datepicker button {
  width: 25px;
  height: 21px;
}

.event-form .date-time {
  display: flex;
  width: fit-content;
}

.event-form .time {
  width: 31%;
}

.time-icon {
  position: relative;
  top: 18px;
  right: 30px;
}

.calendar input.rmdp-input {
  padding: 1.65rem;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
}

.form-flyer {
  width: 17%;
}

.remove-form-flyer {
  text-decoration: underline;
  cursor: pointer;
}

.chat-support {
  padding: 2rem;
  gap: 1.5rem;
  margin-bottom: 0;
}

.chat-support ul {
  display: block;
}

.chat-support,
.chat-support li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-support li {
  margin-bottom: 2rem;
  line-height: 1.3em;
}

.chat-preview {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-basis: 40%;
}

.chat-support li > div p,
.chat-preview + p,
.chat-preview > div:nth-of-type(2) p {
  font-size: 1.2rem;
}

.chat-support li > div p {
  margin-top: 0.5rem;
  color: #575757;
}

.chat-support > div {
  border: 1px solid rgba(0, 0, 0, 0.158);
  border-radius: 8px;
  padding: 1rem;
  height: 80vh;
}

.chat-support > div:nth-of-type(1) {
  width: 40%;
  overflow-y: auto;
}

.chat-support > div:nth-of-type(2) {
  width: 60%;
}

.chat-support .search {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.158);
  margin-bottom: 2.8rem;
  padding: 0 1rem 0 2rem;
  height: 4rem;
  border-radius: 8px;
}

.chat-support img {
  border-radius: 100%;
  width: 5%;
}

.chat-support li > div img {
  width: 100%;
}

.real-chat img {
  width: 68%;
}

.real-chat header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.158);
  padding: 0;
  height: 5rem;
  margin-bottom: 2rem;
}

.real-chat header > .chat-preview {
  flex-basis: 20%;
}

.chat-preview > div:nth-of-type(1) {
  width: 22%;
  margin-right: 1rem;
}

.real-chat header .chat-preview > div:nth-of-type(1) {
  width: 26%;
  margin-right: 0;
}

.real-chat .availability {
  color: #00c9a8;
}

.real-chat header svg {
  margin-right: 2rem;
}

.real-chat input {
  width: 49rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.096);
  border-radius: 0;
  padding: 1rem 0;
}

.real-chat button {
  padding: 0.8rem 1.3rem;
  font-size: 1.2rem;
}

.real-chat-input > div {
  display: flex;
  align-items: center;
}

.real-chat-input {
  position: absolute;
  bottom: 4.5rem;
}

.real-chat-input svg:nth-of-type(1) {
  margin: 0 1rem;
}

.admin-message {
  background-image: url(../assets/images/admin-message.png);
  background-size: cover;
  background-position: -9px 0px;
  background-repeat: no-repeat;
  max-width: 43%;
  padding: 4rem 4rem 4rem 7rem;
}

.admin-message img {
  width: 100%;
  border-radius: 0;
}

.user-message {
  background-image: url(../assets/images/user-message.png);
  background-position: 12px 0px;
  /* padding: 4rem 3rem 4rem 7rem; */
  margin-right: 5rem;
  max-width: 57%;
}

.emoji-picker-wrapper li {
  display: block;
}

.emoji-picker-wrapper > aside {
  position: absolute;
  left: 0;
  bottom: 0;
}

.emoji-picker-wrapper input {
  padding: 1rem 3rem;
}

.real-chat > div:nth-of-type(1) {
  overflow-y: auto;
  height: 36rem;
}

.real-chat li {
  margin-bottom: 0;
}

.real-chat svg {
  cursor: pointer;
}

.real-chat > div:nth-of-type(1) > div {
  display: flex;
  justify-content: flex-end;
}

.file-preview img {
  border-radius: 0;
  width: 5rem;
  height: 4rem;
}

.file-preview-cancel {
  position: relative;
  bottom: 3rem;
  left: -2rem;
}

.chat-preview + p {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c30532;
  border-radius: 100%;
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  cursor: default;
  margin-right: 1rem;
  font-size: 1rem;
  text-align: center;
}

.user-chat span {
  display: block;
  font-size: 1.1rem;
  margin-top: 0.3rem;
}

ul.resource-page {
  padding: 2rem;
  flex-wrap: wrap;
}

.resource-page li {
  border: 1px solid rgb(228, 228, 228);
  width: 25rem;
}

.resource-page img {
  width: 100%;
}

.resource-page .resource-type {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-left: 9rem;
}

.resource-content {
  padding: 1rem;
  font-family: "Manrope", sans-serif;
}

.resource-content h3 {
  font-weight: 600;
}

.resource-content p {
  margin-top: 0.8rem;
  margin-bottom: 1.5rem;
  color: #8b8b8b;
  font-size: 1.2rem;
}

.resource-content p {
  margin-top: 0.8rem;
  margin-bottom: 1.5rem;
  color: #8b8b8b;
  font-size: 1.15rem;
}

.resource-page li button {
  background-color: #edb4c2;
  border: none;
  padding: 0.3rem;
  border-radius: 6px;
}

.resource-page li button:nth-of-type(1) {
  margin-right: 1.3rem;
}

.resource-page li button:nth-of-type(2) {
  padding: 0.3rem 0.5rem;
}

.resource-page li span {
  color: #c30532;
  cursor: pointer;
  font-weight: 600;
}

.resource-page li > div:nth-of-type(1) {
  display: flex;
  position: absolute;
  padding: 0.7rem;
  align-items: center;
}

.resource-form option {
  font-size: 1.4rem;
}

.resource-page audio {
  width: 25rem;
  position: absolute;
  margin-top: 6rem;
}

.resource-page .lock {
  width: 8%;
}

.resource-page .free-resource {
  margin-left: 12rem;
  margin-top: 0.6rem;
}

.real-chat ul li img {
  border-radius: 0;
}

.real-chat ul li span {
  display: block;
}

.mindfulness-cover {
  border-radius: 8px;
}

.podcast-page li {
  display: flex;
  gap: 1rem;
  padding: 1.7rem 1rem;
  background-color: #bae6d245;
  border: none;
  width: 35rem;
}

.podcast-page li button {
  background: none;
  display: block;
  margin-bottom: 0.7rem;
  font-weight: 700;
}

.podcast-page li svg {
  cursor: pointer;
}

.podcast-page li audio {
  width: 19rem;
  margin-top: -3rem;
}

.admin-login-page img {
  width: 100%;
}

.admin-login-page form {
  width: 50%;
}

.admin-login-page label {
  font-weight: 600;
}

.admin-login-page .email {
  margin-bottom: 3rem;
}

.admin-login-page input {
  display: block;
  margin-top: 0.6rem;
  background-color: #f0d1d8;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
}

.admin-login-page > div:nth-of-type(1) {
  position: absolute;
  width: 10%;
  margin-top: 3rem;
  margin-left: 3rem;
}

.admin-login-page > div:nth-of-type(2) {
  width: 37.5%;
}

.admin-login-page > div:nth-of-type(3) {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-login-page button {
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  margin: 4rem 0 0 0;
}

.mentorship-page li {
  background: none;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: flex;
  width: 33rem;
  gap: 1rem;
  padding: 0.7rem;
  align-items: center;
}

.mentorship-page li p {
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
}

.analytics button {
  background: none;
  border: none;
  text-transform: none;
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding: 0;
}

/* Login Spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

/* Toggle */
.toggle-switch {
  width: 25px;
  height: 10px;
  border-radius: 15px;
  background-color: #d4d4d4;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-switch .slider {
  width: 14px;
  height: 14px;
  top: -2px;
  left: 1px;
  border-radius: 50%;
  background-color: #5a5a5a;
  position: absolute;
  transition: all 0.2s;
}

.toggle-switch .slider.suspend-mentor,
.toggle-switch .slider.suspend-member,
.toggle-switch .slider.suspend-club {
  left: 11px;
  background-color: #c30532;
}

table.recent-actions {
  font-size: 1rem;
  text-align: left;
  border-collapse: collapse;
  margin-top: 1.2rem;
  width: 100%;
}

table.all-notification {
  font-size: 1.3rem;
  font-family: Raleway, sans-serif;
}

table.recent-actions thead {
  border: none;
}

table.recent-actions th {
  font-size: 1rem;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0.5rem 0;
}

table.all-notification th {
  font-size: 1.3rem;
}

table.recent-actions td {
  padding: 0.9rem 0;
  font-weight: 500;
  vertical-align: middle;
}

table.all-members th {
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  padding-bottom: 2rem;
}

table.all-members,
.member-info p {
  font-size: 1.3rem;
  font-family: Raleway, sans-serif;
}

.member-info p > span:nth-of-type(2) {
  font-weight: 700;
  font-size: 1.3rem;
  color: #212529;
}

.finance select {
  background: none;
  border: none;
  width: 4.5rem;
  font-size: 1rem;
}

.finance select:focus {
  outline: none;
  border: none;
}

.finance select > option {
  font-weight: 400;
  font-size: 1rem;
  color: #322004;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .admin-login-page > div:nth-of-type(1) {
    width: 20%;
  }

  .admin-login-page > div:nth-of-type(2) {
    display: none;
  }

  .admin-login-page > div:nth-of-type(3) {
    width: 100%;
  }
}
